// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { getDatabase } from "@firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAzSFgQ5uj7taKU69OUKdd1HQJ0CFSUYd4",
  authDomain: "propertylistingapp-4a13d.firebaseapp.com",
  databaseURL: "https://propertylistingapp-4a13d-default-rtdb.firebaseio.com",
  projectId: "propertylistingapp-4a13d",
  storageBucket: "propertylistingapp-4a13d.appspot.com",
  messagingSenderId: "548807502976",
  appId: "1:548807502976:web:b3764ee5b57508a3c6ca65",
  measurementId: "G-GK223KWS77",
};

// Initialize Firebase
//const app = () => initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
export { app, database };
