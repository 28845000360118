import { useParams } from "react-router-dom";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { app } from "./util/firebase";
import { useEffect, useState } from "react";
import "./App.css";

function App() {
  const params = useParams();
  const [videoUrl, setVideoUrl] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    const storage = getStorage(app);
    const videoPathRef = ref(
      storage,
      `gs://propertylistingapp-4a13d.appspot.com/Videos/${params.id}`
    );
    const thumbnailPathRef = ref(
      storage,
      `gs://propertylistingapp-4a13d.appspot.com/VideoThumbnails/${params.id}.jpg`
    );

    getDownloadURL(videoPathRef).then((link) => setVideoUrl(link));
    getDownloadURL(thumbnailPathRef).then((link) => setThumbnailUrl(link));
  });

  return (
    <div className="App">
      {videoUrl && (
        <div className="video-container">
          <video controls poster={thumbnailUrl || ""}>
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div>
      )}
    </div>
  );
}

export default App;
